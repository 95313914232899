<template>
    <form @submit.prevent="submitForm">
        <div class="grid grid-cols-2 lg:grid-cols-11 gap-1 mb-5" v-if="!complexRoute">
            <HomeHeroCitySelect class="rounded-2xl lg:rounded-e-none col-span-2" icon="hugeicons:airplane-take-off-01"
                :label="t('from')" v-model="cityFrom" :options="cities.origin" />
            <HomeHeroCitySelect class="col-span-2 rounded-2xl lg:rounded-none" icon="hugeicons:airplane-landing-01"
                :label="t('to')" v-model="cityTo" :options="cities.destination" />
            <HomeHeroInput class="col-span-2 rounded-2xl lg:rounded-none" icon="hugeicons:calendar-03"
                :label="t('there')" type="date" v-model="start" />
            <HomeHeroInput class="col-span-2 rounded-2xl lg:rounded-none" icon="hugeicons:calendar-03"
                :label="t('back')" type="date" v-model="end" :disabled="!back" />
            <HomeHeroPassenger class="col-span-2 lg:col-span-3 rounded-2xl lg:rounded-s-none " v-model:adults="adults"
                v-model:flight="flight" v-model:children="children" v-model:infants="infants"
                v-model:infants-seat="infantsWithSeat" />
        </div>
        <template v-else>
            <div class="grid grid-cols-2 lg:grid-cols-6 gap-1 mb-5 relative" v-for="(flight, i) in routeArray" :key="i">
                <HomeHeroCitySelect class="rounded-2xl lg:rounded-e-none col-span-2"
                    icon="hugeicons:airplane-take-off-01" :label="t('from')" v-model="routeArray[i].from"
                    :options="cities.origin" />
                <HomeHeroCitySelect class="col-span-2 rounded-2xl lg:rounded-none" icon="hugeicons:airplane-landing-01"
                    :label="t('to')" v-model="routeArray[i].to" :options="cities.destination" />
                <HomeHeroInput class="col-span-2 rounded-2xl lg:rounded-s-none" icon="hugeicons:calendar-03"
                    :label="t('when')" type="date" v-model="routeArray[i].when">
                </HomeHeroInput>
                <button @click="routeArray.splice(i, 1)" v-if="i > 0"
                    class="mt-3 lg:mt-0 static lg:absolute top-1/2 lg:-translate-y-1/2 right-0 lg:translate-x-1/2 flex items-center gap-3 justify-center w-full lg:w-fit col-span-2">
                    <div class="p-1 bg-red-500 rounded-full">
                        <Icon name="hugeicons:delete-02" size="20" class="block text-white" />
                    </div>
                    <span class="lg:hidden text-red-500">{{ t('delete') }}</span>
                </button>
            </div>
            <div class="grid grid-cols-2 lg:grid-cols-6 gap-1 mb-5">
                <HomeHeroPayment v-model="payment" class="col-span-2 rounded-2xl lg:rounded-e-none" />
                <HomeHeroPassenger class="col-span-2 rounded-2xl lg:rounded-none" v-model:adults="adults"
                    v-model:flight="flight" v-model:children="children" v-model:infants="infants" v-model:infants-seat="infantsWithSeat"/>
                <button @click="addRoute" type="button"
                    class="border-gray-line border-2 p-4 pl-[48px] col-span-2 hover:bg-[#EEF1F6] hover:border-my-green rounded-2xl lg:rounded-s-none">+
                    {{ t('addRoute') }}
                </button>

            </div>
        </template>

        <div class="flex flex-col-reverse lg:flex-row justify-between align-start gap-4 lg:gap-0">
            <div class="flex flex-col lg:flex-row gap-4">
                <span class="flex items-center gap-2 w-full justify-center lg:w-auto p-4 lg:p-0 cursor-pointer"
                    @click="complexRoute = !complexRoute">
                    <Icon name="hugeicons:ellipse-selection" class="text-my-green w-5 h-5" size="20" />
                    <span class="text-[14px]/[17px] xl:text-[16px]/[19px]">{{ !complexRoute ? t('setComplexRoute')
                        : t('simpleRoute') }}</span>
                </span>
                <label v-if="!complexRoute"
                    class="flex flex-row-reverse lg:flex-row items-center gap-2 w-full justify-between lg:w-auto">
                    <UToggle v-model="back" :ui="{
                        active: 'bg-my-green',
                    }" />
                    {{ t('thereAndBack') }}
                </label>
            </div>
            <div>
                <button
                    class="py-4 px-6 bg-my-green mx-auto block rounded-3xl text-white text-[14px]/[16px] inter transition-shadow button-green">
                    {{ t('ticketSearch') }}
                </button>
            </div>
        </div>
    </form>
</template>

<script setup>
import { useCitiesStore } from '~/stores/cities'

const cityFrom = ref()
const cityTo = ref()
const { locale, t } = useI18n()
const start = ref(new Date())
const end = ref(new Date())
const back = ref(false)

const flight = ref('ekonom')
const adults = ref(1)
const children = ref(0)
const infants = ref(0)
const infantsWithSeat = ref(0)
const { cities, initCities } = useCitiesStore();
await initCities()
const complexRoute = ref(false)

const routeArray = ref([
    {
        from: '',
        to: '',
        when: new Date()
    }
])
function addRoute() {
    routeArray.value.push(
        {
            from: '',
            to: '',
            when: null
        }
    )
}


const payment = ref('Humo')

const query = computed(() => {
    const res = {
        lang: locale.value,
        searchGroupId: 'standard',
        segmentsCount: complexRoute.value ? routeArray.value.length : (back.value ? 2 : 1),
        adultsAmount: adults.value,
        childrenAmount: children.value,
        infantsWithSeatAmount: infantsWithSeat.value,
        infantsWithoutSeatAmount: infants.value
    }

    if (complexRoute.value) {
        routeArray.value.forEach((route, i) => {
            res[`origin-city-code[${i}]`] = route.from
            res[`destination-city-code[${i}]`] = route.to
            res[`date[${i}]`] = route.when?.toLocaleDateString?.('ru')
        })
    } else {
        res[`origin-city-code[0]`] = cityFrom.value
        res[`destination-city-code[0]`] = cityTo.value
        res[`date[0]`] = start.value.toLocaleDateString('ru')

        if (back.value) {
            res[`origin-city-code[1]`] = cityTo.value
            res[`destination-city-code[1]`] = cityFrom.value
            res[`date[1]`] = end.value.toLocaleDateString('ru')
        }
    }

    return Object.entries(res).map(([k, v]) => `${k}=${v}`).join('&')
})

function submitForm() {
    window.open('https://booking.qanotsharq.com/websky_grs/?' + query.value)
}
</script>