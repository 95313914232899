<template>
    <label class="border-2 p-4 pl-[48px] relative"
        :class="[{ 'opacity-50': disabled }, isOpen ? 'border-my-green' : 'border-gray-line', disabled ? '' : 'hover:border-my-green']">
        <div class="flex items-center gap-3 text-gray-text relative">
            <Icon :name="icon" class="absolute -left-8" size="20" />
            <span class=" text-[16px]/[19px]">{{ label }}</span>
        </div>
        <input class="text-[20px]/[24px] w-full outline-none" v-model="model" v-if="type === 'text'" required />
        <UPopover :popper="{ placement: 'bottom-start' }" v-else :disabled v-model:open="isOpen">
            <button type="button">{{ format(model) }}</button>
            <template #panel="{ close }">
                <DatePicker v-model="model" is-required @close="close" required />
            </template>
        </UPopover>
        <div class="absolute top-1/2 -translate-y-1/2 right-0 translate-x-1/2">
            <slot></slot>
        </div>
    </label>
</template>

<script setup>

const model = defineModel()
const props = defineProps({
    icon: {
        type: String,
        default: ''
    },
    label: {
        type: String,
        default: '',
    },
    type: {
        type: String,
        default: 'text'
    },
    disabled: {
        type: Boolean,
        default: false
    }
})
function format(d) {
    return d?.toLocaleDateString?.('ru')
}
const isOpen = ref(false)
</script>