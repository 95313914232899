<template>
    <BaseSelect v-model="model" :options icon="fluent:payment-28-regular" :label="t('payment')"></BaseSelect>
</template>
<script setup>
const { t } = useI18n()
const model = defineModel()
const options = ref([
    {
        label: 'Humo',
        value: 'Humo',
    },
    {
        label: 'Uzcard',
        value: 'Uzcard',
    },
    {
        label: 'Visa / Mastercard',
        value: 'Visa',
    },
])
</script>