<template>
    <div class="border-2 p-4 pl-[48px] relative" ref="wrapper"
        :class="[{ 'opacity-50': disabled }, isOpen ? 'border-my-green' : 'border-gray-line', disabled ? '' : 'hover:border-my-green']">

        <div class="flex items-center gap-3 text-gray-text relative" @click="show = !show">
            <Icon :name="icon" class="absolute -left-8" size="20" />
            <span class=" text-[16px]/[19px]" @click.stop="show = !show">{{ label }}</span>
        </div>
        <button class="text-[20px]/[24px] w-full outline-none text-left" @click.stop="show = !show">{{ text }}</button>
        <Transition name="fade" appear>
            <div class="absolute border-2 border-my-green  -left-[2px] top-[110%] z-10 bg-white p-4 invisible lg:visible"
                v-if="show" style="width: calc(100% + 4px)">
                <URadio v-for="opt in options" :key="opt.value" v-bind="opt" v-model="model" color="green" :ui="{
                    wrapper: 'mb-4 last:mb-0 items-center',
                    label: 'text-[20px]/[24px]'
                }" :name="label" />
            </div>
        </Transition>
        <BaseDrawer v-model="show" @overlay="show = false" class="lg:invisible">
            <div class="relative text-center font-medium text-[20px]/[24px] pb-4 border-b border-gray-line">
                {{ label }}
                <Icon class="absolute top-[4px] right-0" size="20" name="hugeicons:cancel-circle" />
            </div>
            <URadio v-for="opt in options" :key="opt.value" v-bind="opt" v-model="model" color="green" :ui="{
                wrapper: 'py-4 items-center',
                label: 'text-[20px]/[24px]'
            }" :name="label + 'mobile'" />
        </BaseDrawer>
    </div>

</template>
<script setup>
import { onClickOutside } from '@vueuse/core'

const props = defineProps({
    icon: {
        type: String,
    },
    label: {
        type: String
    },
    options: {
        required: true,
        type: Array
    }
})
const model = defineModel()
const wrapper = ref()
const show = ref(false)
onClickOutside(wrapper, () => show.value = false)

const text = computed(() => {
    const current = props.options.find(el => el.value === model.value)
    if (!current) return '';
    return current.label
})
watch(() => model.value, () => show.value = false)
</script>