export const useCitiesStore = defineStore("cities", () => {
  const rc = useRuntimeConfig();
  const cities = reactive({
    origin: [],
    destination: [],
  });
  const inner = {
    promise: null,
  };
  async function setCities() {
    const res = await $fetch(
      rc.public.api + "/api/online-table/get-dependence-cities"
    );
    cities.origin = res.origin;
    cities.destination = res.destination;
  }
  async function initCities() {
    if (!inner.promise) {
      inner.promise = setCities();
    }
    return inner.promise;
  }
  const allCities = computed(() => {
    const res = [...cities.origin];
    cities.destination.forEach((el) => {
      if (!res.some((e) => e.codeEn === el.codeEn)) res.push(el);
    });
    return res;
  });
  return {
    cities,
    initCities,
    allCities,
  };
});
