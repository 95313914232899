<template>
    <div class=" border-2 p-4 pl-[48px] relative border-gray-line hover:border-my-green" ref="wrapper">

        <div class="flex items-center gap-3 text-gray-text relative">
            <Icon name="hugeicons:user" class="absolute -left-8" size="20" />
            <span class=" text-[16px]/[19px]">{{ t('passengerAndClass') }}</span>
            <Icon class="transform transition-transform duration-200 absolute top-[50%] end-0"
                name="hugeicons:arrow-down-01" size="24" :class="show ? 'rotate-180' : ''"
                @click.prevent.stop="show = !show" />
        </div>
        <div>
            <button @click.prevent.stop="show = !show">{{ adults + children + infants + infantsWithSeat }} {{
                t('passenger') }}, {{
                    flightDisplay
                }}</button>
        </div>
        <Transition name="fade" appear>
            <div class="absolute border border-my-green w-full min-w-[384px] -left-[2px] top-[110%] z-10 bg-white p-4 hidden lg:block"
                v-if="show">
                <div class="pb-2 border-b border-gray-line mb-2">
                    {{ t('passengerCount') }}
                </div>
                <!-- ADULTS -->
                <div class="grid grid-cols-[1fr_102px] items-center gap-3 pb-2 mb-3">
                    <div>
                        <div class="font-medium text-[16px]/[24px]">{{ t('adults') }}</div>
                        <div class="text-gray-text text-[16px]/[19px] font-normal">
                            {{ t('age12plus') }}
                        </div>
                    </div>
                    <div class="flex items-center justify-between">
                        <button type="button" class="p-1 rounded-full"
                            :class="adults > 0 ? 'bg-my-green' : ' bg-gray-line'" :disabled="adults === 0"
                            @click="adults--">
                            <Icon name="hugeicons:minus-sign" size=24 class="block"
                                :class="adults > 0 ? 'text-white' : ''" />
                        </button>
                        <span>
                            {{ adults }}
                        </span>
                        <button type="button" class="p-1 rounded-full bg-my-green" @click="adults++">
                            <Icon name="hugeicons:plus-sign" size=24 class="block text-white" />
                        </button>
                    </div>

                </div>
                <!-- CHILDREN -->
                <div class="grid grid-cols-[1fr_102px] items-center gap-3 pb-2 mb-3">
                    <div>
                        <div class="font-medium text-[16px]/[24px]">{{ t('children') }}</div>
                        <div class="text-gray-text text-[16px]/[19px] font-normal">
                            {{ t('ageTo12') }}
                        </div>
                    </div>
                    <div class="flex items-center justify-between">

                        <button type="button" class="p-1 rounded-full"
                            :class="children > 0 ? 'bg-my-green' : ' bg-gray-line'" :disabled="children === 0"
                            @click="children--">
                            <Icon name="hugeicons:minus-sign" size=24 class="block"
                                :class="children > 0 ? 'text-white' : ''" />
                        </button>
                        <span>
                            {{ children }}
                        </span>
                        <button type="button" class="p-1 rounded-full bg-my-green" @click="children++">
                            <Icon name="hugeicons:plus-sign" size=24 class="block text-white" />
                        </button>
                    </div>
                </div>
                <!-- INFANTS -->
                <div class="grid grid-cols-[1fr_102px] items-center gap-3 pb-2 mb-3">
                    <div>
                        <div class="font-medium text-[16px]/[24px]">{{ t('infants') }}</div>
                        <div class="text-gray-text text-[16px]/[19px] font-normal">
                            {{ t('ageTo2') }}
                        </div>
                    </div>
                    <div class="flex items-center justify-between">
                        <button type="button" class="p-1 rounded-full"
                            :class="infants > 0 ? 'bg-my-green' : ' bg-gray-line'" :disabled="infants === 0"
                            @click="infants--">
                            <Icon name="hugeicons:minus-sign" size=24 class="block"
                                :class="infants > 0 ? 'text-white' : ''" />
                        </button>
                        <span>
                            {{ infants }}
                        </span>
                        <button type="button" class="p-1 rounded-full bg-my-green" @click=" infants++">
                            <Icon name="hugeicons:plus-sign" size=24 class="block text-white" />
                        </button>
                    </div>
                </div>
                <!-- INFANTS with seat-->
                <div class="grid grid-cols-[1fr_102px] items-center gap-3 pb-2 mb-3">
                    <div>
                        <div class="font-medium text-[16px]/[24px]">{{ t('infants') }}</div>
                        <div class="text-gray-text text-[16px]/[19px] font-normal">
                            {{ t('ageTo2Seat') }}
                        </div>
                    </div>
                    <div class="flex items-center justify-between">
                        <button type="button" class="p-1 rounded-full"
                            :class="infantsWithSeat > 0 ? 'bg-my-green' : ' bg-gray-line'"
                            :disabled="infantsWithSeat === 0" @click="infantsWithSeat--">
                            <Icon name="hugeicons:minus-sign" size=24 class="block"
                                :class="infantsWithSeat > 0 ? 'text-white' : ''" />
                        </button>
                        <span>
                            {{ infantsWithSeat }}
                        </span>
                        <button type="button" class="p-1 rounded-full bg-my-green" @click=" infantsWithSeat++">
                            <Icon name="hugeicons:plus-sign" size=24 class="block text-white" />
                        </button>
                    </div>
                </div>

                <div class="pb-2 border-b border-gray-line mb-2">
                    {{ t('serviceClass') }}
                </div>
                <div>
                    <URadio v-for="opt in flightOptions" :key="opt.value" v-bind="opt" v-model="flightType"
                        color="green" :ui="{
                            wrapper: 'relative flex items-start flex-row-reverse justify-between pb-2 border-b mb-3 last:mb-0'
                        }" name="payment" />
                </div>
            </div>
        </Transition>
        <BaseDrawer v-model="show" @overlay="show = false" class="lg:hidden">
            <div class="pb-2 border-b border-gray-line mb-2">
                {{ t('passengerCount') }}
            </div>
            <!-- ADULTS -->
            <div class="grid grid-cols-[1fr_102px] items-center gap-3 pb-2 mb-3">
                <div>
                    <div class="font-medium text-[16px]/[24px]">{{ t('adults') }}</div>
                    <div class="text-gray-text text-[16px]/[19px] font-normal">
                        {{ t('age12plus') }}
                    </div>
                </div>
                <div class="flex items-center justify-between">
                    <button class="p-1 rounded-full" :class="adults > 0 ? 'bg-my-green' : ' bg-gray-line'"
                        :disabled="adults === 0" @click="adults--">
                        <Icon name="hugeicons:minus-sign" size=24 class="block"
                            :class="adults > 0 ? 'text-white' : ''" />
                    </button>
                    <span>
                        {{ adults }}
                    </span>
                    <button class="p-1 rounded-full bg-my-green" @click="adults++">
                        <Icon name="hugeicons:plus-sign" size=24 class="block text-white" />
                    </button>
                </div>

            </div>
            <!-- CHILDREN -->
            <div class="grid grid-cols-[1fr_102px] items-center gap-3 pb-2 mb-3">
                <div>
                    <div class="font-medium text-[16px]/[24px]">{{ t('children') }}</div>
                    <div class="text-gray-text text-[16px]/[19px] font-normal">
                        {{ t('ageTo12') }}
                    </div>
                </div>
                <div class="flex items-center justify-between">

                    <button class="p-1 rounded-full" :class="children > 0 ? 'bg-my-green' : ' bg-gray-line'"
                        :disabled="children === 0" @click="children--">
                        <Icon name="hugeicons:minus-sign" size=24 class="block"
                            :class="children > 0 ? 'text-white' : ''" />
                    </button>
                    <span>
                        {{ children }}
                    </span>
                    <button class="p-1 rounded-full bg-my-green" @click="children++">
                        <Icon name="hugeicons:plus-sign" size=24 class="block text-white" />
                    </button>
                </div>
            </div>
            <!-- INFANTS -->
            <div class="grid grid-cols-[1fr_102px] items-center gap-3 pb-2 mb-3">
                <div>
                    <div class="font-medium text-[16px]/[24px]">{{ t('infants') }}</div>
                    <div class="text-gray-text text-[16px]/[19px] font-normal">
                        {{ t('ageTo2') }}
                    </div>
                </div>
                <div class="flex items-center justify-between">
                    <button class="p-1 rounded-full" :class="infants > 0 ? 'bg-my-green' : ' bg-gray-line'"
                        :disabled="infants === 0" @click="infants--">
                        <Icon name="hugeicons:minus-sign" size=24 class="block"
                            :class="infants > 0 ? 'text-white' : ''" />
                    </button>
                    <span>
                        {{ infants }}
                    </span>
                    <button class="p-1 rounded-full bg-my-green" @click="infants++">
                        <Icon name="hugeicons:plus-sign" size=24 class="block text-white" />
                    </button>
                </div>
            </div>
            <!-- INFANTS with seat -->
            <div class="grid grid-cols-[1fr_102px] items-center gap-3 pb-2 mb-3">
                <div>
                    <div class="font-medium text-[16px]/[24px]">{{ t('infants') }}</div>
                    <div class="text-gray-text text-[16px]/[19px] font-normal">
                        {{ t('ageTo2Seat') }}
                    </div>
                </div>
                <div class="flex items-center justify-between">
                    <button class="p-1 rounded-full" :class="infantsWithSeat > 0 ? 'bg-my-green' : ' bg-gray-line'"
                        :disabled="infantsWithSeat === 0" @click="infantsWithSeat--">
                        <Icon name="hugeicons:minus-sign" size=24 class="block"
                            :class="infantsWithSeat > 0 ? 'text-white' : ''" />
                    </button>
                    <span>
                        {{ infantsWithSeat }}
                    </span>
                    <button class="p-1 rounded-full bg-my-green" @click="infantsWithSeat++">
                        <Icon name="hugeicons:plus-sign" size=24 class="block text-white" />
                    </button>
                </div>
            </div>

            <div class="pb-2 border-b border-gray-line mb-2">
                {{ t('serviceClass') }}
            </div>
            <div>
                <URadio v-for="opt in flightOptions" :key="opt.value" v-bind="opt" v-model="flightType" color="green"
                    :ui="{
                        wrapper: 'relative flex items-start flex-row-reverse justify-between pb-2 border-b mb-3 last:mb-0'
                    }" name="payment-mobile" />
            </div>
            <div>
                <button
                    class="py-4 px-6 mt-4 bg-my-green mx-auto block rounded-3xl text-white text-[14px]/[16px] inter transition-shadow button-green">
                    {{ t('done') }}
                </button>
            </div>
        </BaseDrawer>
    </div>
</template>


<script setup>
import { onClickOutside } from '@vueuse/core'
const { t } = useI18n()
const model = defineModel()
const props = defineProps({

    type: {
        type: String,
        default: 'text'
    }
})
const show = ref(false)
const wrapper = ref()
onClickOutside(wrapper, () => show.value = false)
const flightType = defineModel('flight')
const flightOptions = computed(() => [
    {
        value: 'ekonom',
        label: t('ekonomClass')
    },
    {
        value: 'business',
        label: t('businessClass')
    },
])
const flightDisplay = computed(() => {
    return flightOptions.value.find(el => el.value === flightType.value)?.label?.split?.(' ')?.[0] || ''
})

const adults = defineModel('adults')
const children = defineModel('children')
const infants = defineModel('infants')
const infantsWithSeat = defineModel('infants-seat')

defineShortcuts({
    escape: () => show.value = false
})
</script>