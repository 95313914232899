<template>
    <div>
        <Transition name="fade" appear>
            <div class="overlay z-50 fixed inset-0 bg-black opacity-50" v-if="model" @click="emits('overlay')">
            </div>
        </Transition>
        <Transition name="drawer" appear>
            <div class="fixed z-[51] bottom-0 w-screen bg-white left-0 p-4 pb-6 my-drawer" v-if="model">
                <slot></slot>
            </div>
        </Transition>
    </div>
</template>

<script setup>
const model = defineModel()
const emits = defineEmits('overlay')
</script>